* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --dark-blue: #0d1d2b;
  --dark-blue-hover: #17314a;
  --light-blue: #8aa9c7;
  --oldlace: oldlace;
  --white: #fff;
  --off-white: #f9f9fa;
  --grey: #232836;
  --light-gray: #808080;
  --poppins: 'Poppins', sans-serif;
  --h1: 2rem;
  --h2: 1.5rem;
  --h3: 1.2rem;
  --h4: 1rem;
  --h5: 0.9rem;
  --body-text: 0.8rem;
  --blue: #0284fe;

  --shadow: 0 0 10px rgb(0 0 0 / 9%);

  --border-radius: 0.4rem;
  /* --text-color:#6b7280; */


}


@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300&family=Tinos&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tinos', serif;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #FFF;
  /* background: -webkit-linear-gradient(to right, #004e92, #000428); 
  background: linear-gradient(to right, #004e92, #000428); 
  z-index: 100;*/
  transition: all 0.5s ease;
}

.sidebar.close {
  width: 78px;
  z-index: 1;
}

.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.sidebar .logo-details img {
  height: 50px;
  min-width: 50px;
}

.sidebar .logo-details .logo_name {
  font-size: 25px;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
  color: #2266F7;
}

.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links {
  height: 100%;
  padding: 0 0 150px 0;
  overflow: auto;
}

.sidebar.close .nav-links {
  overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
  padding: 10px 0;
}

/* .sidebar .nav-links li:hover {
  background: #1d1b31;
} */
.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar.close .nav-links li .icon-link {
  display: block;
}

.sidebar .nav-links li img {
  min-width: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 5px;
}

.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}

.sidebar.close .nav-links i.arrow {
  display: none;
}

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 10px;
  padding: 0px 16px;
}

.sidebar .nav-links li a .link_name {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  transition: all 0.4s ease;
}

.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
  z-index: 2 !important;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}

/* .sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
} */
.home-section {
  position: relative;
  background: #e4e9f7;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}

.sidebar.close~.home-section {
  left: 78px;
  width: calc(100% - 78px) !important;
}

.home-section .home-content {
  height: 50px;
  display: flex;
  align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

.void-sidenav {
  height: 80px;
}

.sidenav-active a {
  padding: 10px 0;
  /* background:#F1F1F9; */
  border-radius: 0 8px 8px 0;
}

.sidenav-images a img {
  height: 24px;
  width: 24px;
}

.sidenav-images a span {
  color: gray;
}

.sidenav-images:hover {
  background: #F1F1F9;
  ;
}

.home-nav {
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.notfication-image {
  height: 24px;
  width: 24px;
}

.profile-image {
  height: 40px;
  width: 40px;
}

.home-nav-content h5 {
  font-size: 22px;
  font-family: sans-serif;
}

.home-nav-content p {
  font-size: 14px;
  font-family: sans-serif;
}

.home-nav-content p span {
  color: #005DFF;
  font-family: sans-serif;
}

.home-nav-content-images {
  display: flex;
  gap: 10px;
  align-items: center;
}

.horizontal-row {
  margin-top: 0.5rem;
  margin-bottom: 0;
  background: #e4e9f7;
}

.card-section {
  position: relative;
  background: #e4e9f7;
  left: 260px !important;
  width: calc(100% - 260px) !important;
  transition: all 0.5s ease;
  padding: 30px 0;
}

.sidebar.close~.card-section {
  left: 78px !important;
  width: calc(100% - 78px) !important;
}

.card-section .card img {
  height: 46px;
  width: 46px;
  margin: 18px;
}

.card-title {
  font-size: 12px;
  color: #797979;
}

.card-body h4 {
  font-size: 24px !important;
  font-weight: 600;
}

.card-body h4 span {
  font-size: 12px;
  font-weight: 600;
}

.card-body .arrow-div-admin img {
  height: 14px;
  width: 14px;
}

.card-body .arrow-div-admin p {
  font-size: 10px;
}

.search_icon img {
  height: 18px;
  width: 18px;
  position: absolute;
}

.sr-number-select {
  font-size: 12px;
  padding: 0px 5px;
  border: none;
}

.add-btn-table button {
  padding: 2px 12px;
  border-radius: 10px;
  font-size: 12px;
}

.add-btn-table button span {
  font-size: 15px;
  font-weight: 900;
  margin-left: 5px;
}

.icon-images-table {
  height: 17px;
  margin-right: 5px;
}

.table-row-padding {
  padding: 15px 30px;
  background: white;
  margin: 0 10px;
}

.table-row-padding select {
  height: 28px;
  border-radius: 14px;
  width: 100%;
}

.select-organization {
  width: 20%;
  display: flex;
  align-items: baseline;
  gap: 5px;
  justify-content: end;
}

.search-box-width{
  width: 12%;
}

.table-section-listing {
  position: relative;
  background: #e4e9f7;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding: 30px 0;
}

.sidebar.close~.table-section-listing {
  left: 78px;
  width: calc(100% - 78px);
}

.admin-listing-table {
  margin: 0 10px;
  background-color: #FFF;
}

.add-btn-primary {
  padding: 15px 0 0 15px;
}

/*CSS For Table*/
/* .box {
  background: #fff;
  padding: 24px;
  border-radius: 20px;
}
.actionRow {
  overflow: hidden;
  margin-bottom: 15px;
  transition: all 0.5s;
}
.tabfilter {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #ccc;
  padding: 24px 0;
} */



/*CSS For Table*/


table.list td,
table.list th {
  padding: 1rem;
  text-align: left;
}

.link_name {
  font-size: 16px;
  font-weight: 400;
  transition: all 0.4s ease;
  color: gray;
}


.modal-body .watch-img .profile-image-size {
  height: 90px;
  width: 90px;
}

.profile-image-upload {
  border-radius: 20px;
  height: 110px;
  width: 145px;
}

.search-bar-div .input-group {
  width: 25% !important;
}

.search-icon-img {
  height: 24px;
  width: 24px;
  position: absolute;
  z-index: 1;
  margin: 5px 0 0 10px;
}

.bg-colour-lght {
  background-color: #005DFF4D;
  ;
}

/* imageUPload css */
/* body{background-color:#eee}.height-100{height:100vh}.modal-header>.modal-title{font-size: 14px}.modal-body>.body-desc{font-size: 14px}.modal-footer>.footer-title{font-size: 12px}#loadFile{display: none}.modal-header>.modal-title, .modal-body>.body-desc, .modal-body>.photo-input, .modal-footer>.footer-title{text-align: center} */

.table-row-padding .search-bar-border {
  border-radius: 20px;
}

.admin-listing-table .search-bar-border {
  border-radius: 20px;
}

th {
  background: #F1F1F9 !important;
}

.search-bar-div {
  padding: 12px 18px;
}

.rdt_TableCell{
  position: unset !important;
}

.rdt_TableCell input{
  position:unset !important;
}