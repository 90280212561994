#loader{
    width:100%;
    height:100vh;
    background-color:black;
    position:fixed;
    z-index:9999;
    top:0;
    left:0;
  }
  
  .loader-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    color:#fff;
    background: rgb(114 114 114 / 45%);
  }
  
  /* .loader-wrapper img {
    width: 100px;
  } */