.modal-show {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.drop-list {
    border-bottom: 1px solid rgb(117, 111, 111);
    /* padding: 2px 0; */
}

ul :last-child {
    border-bottom: none;
}

.drop-list-img {
    width: 14px;
    height: 14px;
}

.drop-list a {
    font-size: 10px;
    text-decoration: none;
    color: black;
}