/*CSS for nav starts*/
.nav-bar {
    background: none !important;
}

nav .nav-content {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}

.navbar .nav-content img {
    width: 139px;
    height: 35px;
}

nav .nav-content button {
    background-color: var(--blue);
    border-radius: 8px;
    border: none;
    padding: 2px 3px;
    height: 36px;
    width: 123px;
    color: #fff;
}

.navbar-nav {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    list-style: none;
    gap: 47px;
    margin-left: 70px;
    margin-top: 10px;
}

/* .collapse:not(.show) {
    display: block;
} */

.navbar-logo {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
    gap: 20px;
    margin-right: 40px;
}

.navbar-logo .nav-item-logo img {
    height: 19px;
    width: 19px;
}

.user-img {
    height: 36px;
    width: 36px;
}

/*CSS for nav end*/

/*CSS FOR NEW NAVBAR STARTS*/
.navbar {
    padding-bottom: 0 !important;
}

.navbar-brand {
    display: flex;
    gap: 8px;
    align-items: center;
}

.navbar-brand p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #005DFF;
}

.navbar-nav .nav-item a {
    font-family: 'Source Sans Pro' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #8C8F91 !important;
}

.nav-link-blue {
    font-family: 'Source Sans Pro' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-decoration: none;
    color: #005DFF !important;
}

.blue-nav-item {
    border-bottom: 4px solid #005DFF !important;
}

.blue-nav-item p {
    padding: 15px;
}

/*CSS FOR NEW NAVBAR ENDS*/

.welcome-content {
    height: 885px;
}

.wallpaper-container {
    padding: 21px 42px;
}

.welcome-content .welcome-content-left {
    float: left;
    width: 45%;
}

.welcome-content .welcome-content-right {
    float: right;
    width: 50%;
}

.welcome-content .welcome-content-right .welcome-bg img {
    height: 575px;
    width: 645px;
}


.Singup-wallet {
    height: 100px;
    width: 40%;
}

.Singup-wallet img {
    position: absolute;
    height: 60px;
    width: 20px;
    left: 50%;
    top: 20%
}

.form {
    height: 428px;
    background: #fff;
    padding: 20px;
    border-radius: 16px;
    font-family: 'Source Sans 3';
}

.form-row {
    margin-top: 10px;
}

.form-row label {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Source Sans 3';
}

.form-row .input-box {
    position: relative;
    display: grid;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.warninig {
    color: red;
    margin: 5px;
    font-size: 12px;
}

.warninig02 {
    color: red;
    margin-top: -9px;
    margin-left: 23px;
    font-size: 12px;
}

.form-row .input-box:hover .warninig {
    display: block;
}

.form-row .input-box img {
    position: absolute;
    left: 2%;
    top: 10%;
    height: 20px;
}

.login-btn {
    font-size: 13px;
    font-weight: 600;
}

.briefcase img {
    position: absolute;
    height: 24px;
    width: 24px;
    left: 15%;
    top: 8%;
}

.Phone img {
    position: absolute;
    height: 20px;
    width: 16px;
    left: 20%;
    top: 10%;
}

.form-row .input-box input {
    border: 2px solid #eee;
    padding: 5px 10px 5px 35px;
    font-size: 12px;
    line-height: 20px;
    display: block;
    height: 30px;
    width: 100%;
    outline: 0;
}

.form-row h1 {
    color: #005DFF;
    font-size: 32px;
    font-family: 'Source Sans 3';
}

.form-control {
    font-family: 'Source Sans 3';
}

.form-row a {
    font-size: 12px;
    text-decoration: none;
    text-align: end;
}

.form-row span {
    font-size: 12px;
    font-family: 'Source Sans 3';
    margin-left: 5px;
}

.btn1 {
    background: #005DFF;
    color: #ffff;
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 8px;
    padding: 0 24px;
}

.signup p {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
}

.login-form {
    padding: 120px 0 0 100px;
}

.google-icon {
    position: relative;
}

.google-signin {
    border: 0.5px solid #9799A0;
    background-color: #fff;
    color: #001A42;
    position: relative;
    font-size: 13px;
    font-weight: 600;
}

.Google img {
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    top: 18%;
    left: 30%;
}

.Google-signUp img {
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    top: 18%;
    left: 35%;
}

.forgot-pass {
    display: flex;
    justify-content: space-between;
}

.forgot-pass .rememberme {
    display: flex;
    align-items: end;
}

.forgot-pass .rememberme input {
    margin-bottom: 3px;
}

/* login page css */

.Singup-content .Singup-content-right {
    float: right;
}

.Singup-content .Singup-content-right .Singup-bg img {

    height: 885px;
    width: 645px;
}

.go-login {
    text-align: center;
}

.go-to-login {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
}

/* CSS for Top Header Started */

.bg-light {
    background-color: #FFFFFF !important;
    padding-bottom: 0;
}

.logo-content {
    margin-left: 34px;
    margin-left: 34px;
    gap: 10px;
    display: flex;
    align-items: center;
}

.logo-content img {
    height: 35px;
    width: 35px;
}

.logo-content a {
    text-decoration: none;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}

.nav-item {
    display: flex;
    align-items: center;
}

.selected {
    border-bottom: 5px solid #005DFF;
}

.active {
    border-bottom: 4px so;
}

.nav-item img {
    height: 24px;
    width: 24px;
}

.nav-item a {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #005DFF !important;
}

/* CSS for Top Header End */

/* Banner CSS start */
.banner {
    position: relative;
}

.banner img {
    width: 100%;
    height: 180px;
}

.banner-content {
    position: absolute;
    top: 20%;
    left: 9%;
    width: 30%;
    color: #FFFFFF;
}

.banner-content h2 {
    margin-bottom: 0;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.banner .banner-content p {
    margin-bottom: 20px !important;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07em;
}

.banner .banner-content h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
}

/* Banner CSS start */

/*Token Container CSS Starts*/
.token-container {
    background: #FFFFFF;
    padding: 10px;
    border-radius: 20px;
    position: relative;
}

.token-container .token-container-img img {
    position: absolute;
    width: 230px;
    height: 190px;
    right: 8%;
    top: 0%;
}

.token-container .row .col-6 a {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.token-container .col-6 img {
    height: 24px;
    width: 24px;
}

.token-container .token-container-cards .card-images-tokens {
    height: 30px;
    width: 30px;
    margin: 5px 10px 5px 10px;
}

.token-container .token-container-cards .col-2 p {
    color: #8C8F91;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
}

.token-container .token-container-cards .col-2 h3 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    color: #061340;
}

.buy-more-btn button {
    border: none;
    margin-top: 12px;
    padding: 8px 10px;
    display: flex;
    background: #005DFF;
    gap: 4px;
    border-radius: 8px;
}

.buy-more-btn button img {
    height: 18px;
    width: 18px;
}

.buy-more-btn button p {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 148.9%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
}

/*Token Container CSS Ends*/

/*CSS for Bottom Conatiner Homepage started*/

.bottom-container-hp .bottom-row-hp {
    padding: 0 10px 10px 10px;
    border-radius: 20px;
}

.items-in-card {
    display: flex;
    gap: 12px;
    padding: 0;
}

.items-in-card img {
    height: 24px;
    width: 24px;
}

.recently-added-div {
    display: flex;
    justify-content: end;
    padding: 0;
}

.recently-added-div button {
    display: flex;
    align-items: center;
    border: none;
    padding: 8px 16px;
    background: #F1F1F9;
    border-radius: 8px;
    gap: 8px;
}

.recently-added-div button p {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.4%;
    margin-bottom: 0;
}

.recently-added-div button img {
    height: 16px;
    width: 16px;
}

.token-data-container {
    padding: 24px 0;
}

.token-data-container .row p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.item-img img {
    height: 38px;
    width: 38px;
}

.fwd-arrow img {
    height: 24px;
    width: 24px;
}

.token-detail-row {
    /* padding: 2px 4px; */
    /* border-bottom: 1px solid #C5D2D7; */
    align-items: center;
}

.card-body-header {
    align-items: center;
}

.tdc-row-header {
    background: #F1F1F9;
    border-radius: 10px;
    padding: 8px;
}

.quick-link-header {
    display: flex;
    gap: 10px;
    padding: 14px;
    align-items: end;
}

.quick-link-card-body {
    padding: 0;
}

.quick-link-header .quick-link-gap {
    gap: 10px;
}

.quick-link-header img {
    height: 24px;
    width: 24px;
}

.quick-link-content {
    padding: 20px 0px;
    background: #F1F1F9;
    border-radius: 10px;
    margin: 24px 0;
    align-items: center;
}

.quick-link-content img {
    height: 20px;
    width: 20px;
}


.search-nav {
    display: flex;
    justify-content: space-around;
}

.notification-content {
    font-size: 12px;
    font-weight: 500;
}

/* .form-select {
    width: 60% !important;
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.4%;
    text-align: center;
    color: #2A4153;
} */

.quick-link-header p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.quick-link-content p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #2A4153;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .token-container .token-container-img img {
        position: absolute;
        width: 212px;
        height: 208px;
        right: 8%;
        top: 0%;
    }
}

/*CSS for Bottom Conatiner Homepage ends*/


/*Modal CSS Start*/
.modal-head img {
    height: 24px;
    width: 24px;
}

.modal-head {
    align-items: center;
}

.modal-input label {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.modal-input .input-box input {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding-left: 35px;
}

.modal-header {
    border-bottom: none !important;
    padding: 12px 20px;
}

.modal-footer {
    padding-top: 0 !important;
    border-top: 0 !important;
    padding: 12px 18px;
}

.modal-footer img {
    height: 18px;
    width: 18px;
}

.modal-footer a {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;
}

.modal-footer .checkbox-options .checkbox-content p {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2A4153;
}

.modal-input textarea {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    outline: none;
}

.modal-footer button {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 148.9%;
    border-radius: 8px;
}

.modal-input .input-box {
    position: relative;
    display: grid;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.modal-input .input-box img {
    height: 20px;
    position: absolute;
    left: 2% !important;
    top: 16%;
}

.modal-body .watch-img img {
    height: 170px;
    width: 170px;
    border-radius: 11px;
}


.modal-body .watch-imgnew img {
    height: 170px;
    width: 170px;
    border-radius: 11px;
}

.modal-body .myaccountimg a {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2266F7;
    justify-content: center;
    text-decoration: none;
}

.modal-body .myaccountimg img {
    border-radius: 50%;
    height: 170px;
    object-fit: fill;
}

.modal-input p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6F778B;
    margin: 5px 0;
}

.modal-input a {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2266F7;
    text-decoration: none;
    margin: 5px 0;
}

.modal-footer .color-white {
    background: #FFFFFF;
    color: #005DFF;
    border: 1px solid #005DFF;
    border-radius: 8px;
}

.modal-footer .color-white:hover {
    background: #FFFFFF;
    color: #005DFF;
    border: 1px solid #005DFF;
}

.close {
    border: none;
}

.before-input-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2A4153;
}

.modal-body .input-box input {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8C8F91;
}

.modal-header h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #2A4153;
}

.header-content .row img {
    width: 45px;
    border-radius: 5px;
    height: 40px !important;
}

.header-content .header-content-text h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #005DFF;
    margin-left: 10px;
}

.header-content .header-content-text p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #8C8F91;
    margin-left: 10px;
}

.modal-body .header-content-img {
    text-align: center;
}

.modal-body .header-content-img img {
    width: 100%;
    height: 20px;
    max-width: 20px;

}

.modal-body .row .header-content-text h4 {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #2A4153;
}

.modal-dialog-right {
    position: fixed;
    right: 1%;
    top: 0%;
    padding: 22px;
    width: 100%;
}

.autoGTM-content label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 35px;
    display: flex;
    align-items: center;
    color: #001A42;
    width: 100%;
}



.modal-input .modal-input-label {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #001A42;
}

.modal-input .form-outline textarea {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #8C8F91;
    width: 100%;
    padding: 5px;
    font-family: 'Source Sans 3';
}

.modal-content.sidenav-modal {
    width: 100% !important;
}

/* .modal-content {
    border-radius: 8px !important;
} */

/*Modal CSS End*/



/* CSS For Item Page */
.filter-btn-div {
    position: relative;
}

.filter-btn-div img {
    position: absolute;
}

.input-border-text {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8C8F91;
}

.account-body h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2266F7;
    cursor: pointer !important;
}




/* 
body {
    margin-top: 20px;
    background-color: #f7f7fc;
} */

.timeline {
    list-style-type: none;
    position: relative
}

.timeline:before {
    background: #dee2e6;
    left: 9px;
    width: 2px;
    height: 100%
}

.timeline-item:before,
.timeline:before {
    content: " ";
    display: inline-block;
    position: absolute;
    z-index: 1
}

.timeline-item:before {
    background: #fff;
    border-radius: 50%;
    border: 3px solid #3b7ddd;
    left: 0;
    width: 20px;
    height: 20px
}

.card {
    margin-bottom: 24px;
    box-shadow: 0 0 0.875rem 0 rgba(33, 37, 41, .05);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: initial;
    border: 0 solid transparent;
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-header:first-child {
    border-radius: .25rem .25rem 0 0;
}

.card-header {
    border-bottom-width: 1px;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.card-header {
    padding: 1rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid transparent;
}


/* CSS For Item Page */
.card {
    body {
        background: #f5f5f5;
        margin-top: 20px;
    }

    .card {
        border: none;
        -webkit-box-shadow: 1px 0 20px rgba(96, 93, 175, .05);
        box-shadow: 1px 0 20px rgba(96, 93, 175, .05);
        border-radius: 10%;
        margin-bottom: 30px;
        margin-top: 5%;
    }

    /* .table th { */
    .table th {
        font-weight: 500;
        color: #827fc0;
    }

    .table thead {
        background-color: #f3f2f7;
    }

    .table>tbody>tr>td,
    .table>tfoot>tr>td,
    .table>thead>tr>td {
        padding: 14px 12px;
        vertical-align: middle;
    }

    .table tr td {
        color: #8887a9;
    }

}

.thumb-sm {
    height: 32px;
    width: 32px;
}

.badge-soft-warning {
    background-color: rgba(248, 201, 85, .2);
    color: #f8c955;
}

.badge {
    font-weight: 500;
}

.badge-soft-primary {
    background-color: rgba(96, 93, 175, .2);
    color: #605daf;
}

.card_b {
    margin-top: 30px;
}

/* FILTER MODAL POPUP CSS */
.filter-gap {
    gap: 22px;
}

.filter-footer {
    padding: 0 !important;
    margin: 12px 0 0 0 !important;
}

.filter-footer button {
    margin: 0 !important;
    width: 133px;
}

.filter-input-logo {
    display: flex !important;
    left: 5px !important;
}


/*CSS for item listing page starts*/
.filter-btn-logo {
    background: #FFFFFF;
    border: 2px solid #eee;
    padding: 5px 10px 5px 0px;
    font-size: 14px;
    line-height: 20px;
    display: block;
    height: 40px;
    width: 100%;
    outline: 0;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px rgba(0, 128, 255, 0.15);
    border-radius: 8px;
}

.filter-btn-logo img {
    position: absolute !important;
    left: 1% !important;
    height: 24px;
    width: 24px;
    top: 0% !important;
}

.filter-btn-logo .input-box p {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #425768;
}

.search-row {
    margin: 0 100px !important;
}

.search-logo img {
    position: absolute;
    height: 24px;
    width: 24px;
    left: 1% !important;
}

.add-item-button button {
    background: #005DFF;
    border-radius: 8px;
    border: none;
    padding: 8px 10px;
}

.add-item-button button img {
    height: 24px;
    width: 24px;
}

.add-item-button button p {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 148.9%;
    color: #FFFFFF;
}

.print-qr-div {
    gap: 10px;
}

.print-qr-div img {
    height: 24px;
    width: 24px;
}

.print-qr-div p {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #005DFF;
}

.listing-container {
    background: #FFFFFF;
    border-radius: 20px;
}

.recently-added-item-div {
    background: #F1F1F9;
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    width: 160px;
}

.recently-added-item-div p {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.4%;
    color: #2A4153;
}

.recently-added-item-div img {
    width: 24px;
    height: 24px;
}

.listing-container {
    padding: 32px;
}

.table-container {
    margin: 10px 0;
}

.table-container .table thead {
    padding: 8px 0;
    background: #F1F1F9;
    border-radius: 10px !important;
    margin-top: 10px !important;
}

.table-container .table thead tr {
    background: #F1F1F9;
    border-radius: 10px !important;
    border: none;
}

.table-container .table thead tr th {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A4153;
    padding: 8px 0;
}

.table-container .table tbody tr td {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #2A4153;
    padding: 8px 0;
    vertical-align: middle;
}

.table-container .table tbody tr td img {
    height: 38px;
    border-radius: 11px;
}

.table-container .table tbody tr td input {
    border: 1px solid #8C8F90;
    border-radius: 2px;
    height: 18px;
    width: 18px;
}

.table-container .table tbody tr .img-align-listing {
    display: flex;
    align-items: center;
    gap: 5px;
}

/*CSS for item listing page ends*/


/*CSS For OTP Verification page starts*/
.container .right-container {
    float: right;
}

.container .right-container img {
    width: 100%;
    height: 100vh;
}

.container .left-container {
    float: left;
}

/*CSS For OTP Verification page ends*/
/* ITEM DETAIL PAGE CSS */

.itemDetails-name {
    height: 24px;
    color: #8C8F91;
    font-family: 'Source Sans Pro';
    font-style: normal;
}

.row-2 .itemDetails-div h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px;
    color: #2A4153;
    width: 80%;
}

.itemDetails-div h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    color: #2A4153;
}

.itemDetails-img img {
    width: 163.94px;
    height: 163.94px;
    border-radius: 10px;
}

.itemdetails-padding {
    padding: 60px;
}

.itemDetails-imgages img {
    display: flex;
    /* position: absolute; */
    width: 65px;
    height: 65px;

    border-radius: 10px;
}

.attachments-img img {
    width: 19.2px;
    height: 24px;
    gap: 8px;

}

.itemDetails-name {
    gap: 8px;
}

.itemDetail-container {
    height: 300px !important;
    background: #ffffff !important;
    border-radius: 0 0 50px 50px !important;
}


/*CSS For Two factor page*/
.left-padding {
    padding: 15% 5%;
}

.otp-heading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    color: #2266F7;
}

.otp-para {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 131%;
    color: #2A4153;
}

.label-heading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #001A42;
}

/*CSS For Two factor page*/

.bottomRow .holder .column2 {
    position: relative !important;
    margin-top: 20px;
}

.swiper {
    position: unset !important;
}

.images-caption {
    position: absolute !important;
    top: -36% !important;
}

.heading-caption {
    padding-bottom: 0 !important;
}

.swiper-button-prev:after {
    color: black !important;
    font-size: 22px !important;
}

.swiper-button-next:after {
    color: black !important;
    font-size: 22px !important;
}


/*CSS for New Login Page */
.span-name {
    font-size: 32px !important;
    font-weight: 800 !important;
}

@media (min-width: 992px) {
    form {
        padding: 130px 100px;
    }
}

@media (max-width: 768px) {
    form {
        padding: 100px 20px;
    }
}


.signupPage-Padding {
    padding: 10px 50px;
}

/*CSS for New Login Page */
.upload-file-heading {
    text-align: center;
}

.upload-file-heading label {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.upload-image-div {
    text-align: center;
    padding: 10px;
}

.upload-image-div img {
    width: 75px;
}

.upload-file-div {
    width: 100%;
    margin: auto;
}

.filesList {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 10px;
}

.filesList li {
    position: relative;
}

.imageHandlers {
    display: flex;
    justify-content: center;
}

.filesList .goLeft {
    position: absolute;
    right: 3px;
    bottom: 1px;
}

.imageHandlers .goLeft {
    padding: 6px;
}

.imageHandlers .goLeft .pointer {
    width: 30px;
    height: 20px;
    width: 30px;
    height: 20px;
    position: absolute;
    top: 60%;
    cursor: pointer;
}

.filesList li img {
    width: 100px;
    height: 80px;
    position: relative;
}

.filter-modal-btns {
    display: flex;
    gap: 10px;
    justify-content: end;
}

.token-section {
    margin: 1% 8%;
}

.table_structure {
    height: 509px;
}

.table_structure .dlUCce {
    height: 268px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.table_structure th,
.table_structure td {
    padding: 0 !important;
}

.login-banner-img {
    height: 100vh;
}

.otp-padding input {
    padding: 0 8px !important;
}

.card-text p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #8C8F91;
}

.card-title {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #061340;
}

.card-border {
    border-radius: 13px;
}

.items-heading {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #000000;
}

.slider-signup {
    animation: signupBanner 10s infinite;
    border-radius: 30px;
    background: url(../images/compressed/signupbanner1.webp) no-repeat center center fixed;
    background-attachment: inherit;
    background-size: cover;
    height: 95vh;
    overflow: hidden;
}

.container-background {
    background-color: #FFFFFF;
}

.faq-section {
    font-family: 'Source Sans Pro';
    font-style: normal;
}

.expand-para-heading {
    color: #005DFF;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
}

.expand-para {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.faq-row {
    padding: 20px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 10px;
}

.quick-link-content-icon {
    height: 20px !important;
    width: 20px !important;
}

.item-content {
    height: 380px;
}

.item-content .dlUCce {
    height: 210px;
    overflow-y: scroll;
}

.quick-link-bottom {
    padding-bottom: 35px !important;
}

.search_icon img {
    height: 18px;
    width: 18px;
    position: absolute;
}

.selectClick img {
    height: 18px;
}

.erhqsJ {
    font-size: 12px !important;
}

.createButton img {
    height: 18px;
}

.sidenav-padding {
    padding: 10px;
}

.addnoteBtn img {
    height: 18px;
    width: 18px;
}

.trash_icon img {
    height: 18px;
}

.close_icon img {
    height: 18px;
}

.btn-Cancle-invite {
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 8px;
}

.sc-ePzlA-D .biA-DkN {
    height: 268px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.attchedfile {
    display: flex;
}

.attchedfile .pointer {
    font-size: 10px;
}

.yesBtn {
    padding: 4px 12px;
    background: #FFFFFF;
    border: 1px solid #005DFF;
    color: #005DFF;
}

.yesBtn:hover {
    background: #005DFF;
    color: #FFFFFF;
    border: none;
}

.noBtn {
    padding: 4px 12px;
}

.cursor-pointer {
    cursor: pointer;
}

.swiper-img {
    height: 40px;
    width: 95%;
    object-fit: cover;
}

.accept-invite-padding {
    padding: 40px 50px !important;
}

.item-listing-div {
    height: 210px;
    overflow-y: scroll;
}

.arrow-div {
    display: flex;
    justify-content: end;
}

.item-detail-token-list {
    height: 570px !important;
}

.profile-edit-img {
    height: 100px !important;
    width: 100px !important;
}

.table-header {
    padding: 2px 4px !important;
}

.addnotes {
    display: flex;
    gap: 10px;
    margin-top: 50px;
    justify-content: end;
}


/* CSS For Walkthrow Page */
.swiper-walkthrow {
    position: relative;
    height: 100%;
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    padding: 20px 40px 50px 40px;
    border-radius: 60px;
}

.swiper-walkthrow img {
    width: 100%;
    height: 85vh;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slider {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.walkthrow-img {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.walkthrow-containers {
    border-radius: 60px;
}

.walkthrow-btns {
    position: absolute;
    bottom: 12%;
    right: 6%;
    z-index: 1;
    display: flex;
    gap: 10px;
}

.skip-btn-walkthrow {
    padding: 4px 10px;
    border-radius: 8px;
    border: none;
    background-color: #FFFFFF;
    color: #005DFF;
    border: 1px solid #005DFF;
}

.next-btn-walkthrow {
    padding: 4px 10px;
    border-radius: 8px;
    border: none;
    background-color: #005DFF;
    color: #FFFFFF;
}

.notification-font {
    font-size: 10px;
}

.activity-padding {
    padding-left: 17px;
}

.notification-modal {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;
}

.n-time {
    font-size: 8px;
    margin-left: 10px;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
/* ::-webkit-scrollbar-thumb { */
/* background:#005DFF;  */
/* border-radius: 10px; */
/* background: lightgray; */
/* background: white; */
/* } */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  } */
/* CSS For Walkthrow Page */



@keyframes signupBanner {
    0% {
        background-image: url(../images/compressed/signupbanner1.webp);
    }

    15% {
        background-image: url(../images/compressed/signupbannerempty3.webp);
    }

    30% {
        background-image: url(../images/compressed/signupbanner2.webp)
    }

    45% {
        background-image: url(../images/compressed/signupbanner3.webp);
    }

    60% {
        background-image: url(../images/compressed/signupbanner3.webp);
    }

    75% {
        background-image: url(../images/compressed/signupbannerempty2.webp);
    }

    90% {
        background-image: url(../images/compressed/signupbanner4.webp);
    }

    100% {
        background-image: url(../images/compressed/signupbannerempty2.webp)
    }
}

/*CSS for new Homescreen starts*/
@media (min-width:567px) and (max-width: 768px) {

    /* For mobile phones: */
    .banner-text-content h1 {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .btn-outline-success {
        position: absolute;
        right: 1% !important;
        top: 10% !important;
    }
}

/*CSS for new Homescreen starts*/

/* mobile view */
@media screen and (max-width:768px) {
    #headerCntr .logo {
        padding: 7px 12px 7px 0 !important;
    }

    .banner img {
        height: 120px;
    }

    .banner-content {
        position: absolute;
        top: 20%;
        left: 5%;
        width: 60%;
        color: #FFFFFF;
    }

    .banner-content h2 {
        margin-bottom: 0;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }

    .banner .banner-content h5 {
        display: none;
    }

    .token-container {
        margin-top: 10px;
    }

    .bottom-container-hp {
        margin: 5px;
    }

    .card-images-tokens {
        display: block !important;
        margin: 5px auto !important;
    }

    .card-text {
        text-align: center !important;
    }

    .card-title {
        text-align: center !important;
    }

    .buy-more-btn {
        display: flex !important;
        justify-content: center !important;
    }

    .token-container .token-container-cards {
        gap: 8px !important;
    }

    .Google img {
        position: absolute;
        z-index: 1;
        width: 24px;
        height: 24px;
        top: 18%;
        left: 26% !important;
    }

    .signupPage-Padding {
        padding: 10px 5px !important;
    }

}

@media screen and (min-width:768px) and (max-width:1200px) {
    .Google img {
        position: absolute;
        z-index: 1;
        width: 24px;
        height: 24px;
        top: 18%;
        left: 26% !important;
    }

    .banner-content {
        position: absolute;
        top: 20%;
        left: 5%;
        width: 60%;
        color: #FFFFFF;
    }

    .banner-content h2 {
        margin-bottom: 0;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 20px;
    }

    .banner .banner-content h5 {
        display: none;
    }
}

#status-selectbox {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.pointer-evt-none {
    pointer-events: none;
}


.rdt_TableHead {
    z-index: 0 !important;
}